import React from 'react'
import './Demo.css'
import Footer from '../../Components/Footer/Footer'
import BtnWhatsapp from '../../Components/BtnWhatsapp/BtnWhatsapp'
import Hero from '../../Components/Hero/Hero';
import About from '../../Components/About/About';
import ButonScroll from '../../Components/ButonScroll/ButonScroll';
import Videos from '../../Components/Videos/Videos';
import Contact from '../../Components/Contact/Contact';
import Realidad from '../../Components/Realidad/Realidad';
import Solucion from '../../Components/Solucion/Solucion';
import ScrollDemos from '../../Components/ScrollDemos/ScrollDemos';
import Planes from '../../Components/Planes/Planes';
export default function Demo() {
    return (
        <section className='demo'>
            <Hero />
            <About />
            <ScrollDemos />
            <Videos />
            {/* <Planes /> */}
            <Realidad />
            <Solucion />
            <Contact />
            <Footer />
            <ButonScroll />
            <BtnWhatsapp />
        </section>
    )
}
